import { ref } from "vue";
import { auth } from "../firebase/config";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";

const useSignup = async (email, password, displayName) => {
  const error = ref(null);
  const redEmail = ref(false);
  const redPassword = ref(false);
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    if (res) {
      await updateProfile(res.user, { displayName: displayName });
    } else {
      error.value = "Registration Failed... please try again";
    }
  } catch (err) {
    switch (err.code) {
      case "auth/email-already-in-use":
        error.value = "The Email you have entered is already registered in one of our websites, Please try to Login using this email"
        redEmail.value = true;
        break;
      case "auth/invalid-email":
        error.value = "Please try again with a valid Email"
        redEmail.value = true;
        break;
      case "auth/weak-password":
        error.value = "Password should be at least SIX characters"
        redPassword.value = true
        break;
      default:
        error.value = err.code;
        break;
    }
  }
  return { error, redEmail, redPassword };
};
export default useSignup;
