import { ref } from "vue";
import { db } from "../firebase/config"
import { doc, getDoc, updateDoc, serverTimestamp } from "firebase/firestore"


const getDocument = async (email, addVisit) => {
    const data = ref(null);
    const docError = ref(null);
    try {
        const res = await getDoc(doc(db, "sleep", email))
        data.value = res.data()
        if (addVisit) {
            const allDates = data.value.timeVisited
            const x = data.value.visited + 1
            const y = serverTimestamp()
            await updateDoc(doc(db, "sleep", email), "visited", x, "timeVisited", y)
        }
    } catch (err) {
        console.log(err);
        docError.value = err.message
    }
    return { data, docError }
}
export default getDocument