import { ref } from "vue"
import { auth } from "../firebase/config"
import { signInWithEmailAndPassword } from "firebase/auth";

const useLogin = async (email, password) => {
    const error = ref(null)
    try {
       const res = await signInWithEmailAndPassword(auth, email, password)
       if (res) {
           console.log("Logged in");
       } else {
           error.value = "Login Fail... please try again"
       }
    } catch (err) {
        console.log(err);
        error.value = "Login Fail... please try again"
    }
    return {error}
}
export default useLogin