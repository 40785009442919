import { ref } from "vue";
import { db } from "../firebase/config";
import { doc, setDoc } from "firebase/firestore";

const addDocument = async (document) => {
  const docError = ref(null);
  try {
    await setDoc(doc(db, "sleep", document.email), document);
  } catch (err) {
    console.log(err);
    docError.value = err.message;
  }
  return { docError };
};
export default addDocument;
