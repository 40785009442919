<template>
  <div class="case">
    <!-- <div class="p-col-fixed title">
      <p>{{ caseNo }}</p>
    </div> -->
    <div class="desc">
      <h3>{{ dr }}</h3>
      <p>{{ report }}</p>
    </div>
    <div class="button">
      <slot name="butt"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["caseNo", "dr", "report"],
  setup() {
    return {};
  },
};
</script>

<style scoped>
.case {
  background-color: white;
  margin: 7px 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  position: relative;
}

.case:hover {
  margin: 7px 15px;
}

.title {
  display: inline-block;
  width: 17%;
}

.desc {
  text-align: left;
  padding-left: 20px;
  flex: 1 1 0;
}

.button {
  flex: 1 1 0;
}
</style>
