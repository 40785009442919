import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth"
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAkAGHBQYpEJ4f8zvfQRUQk5lkKmyksBbs",
  authDomain: "minds-c85e5.firebaseapp.com",
  projectId: "minds-c85e5",
  storageBucket: "minds-c85e5.appspot.com",
  messagingSenderId: "537902541731",
  appId: "1:537902541731:web:b2b5fe694988215ba90816",
  measurementId: "G-DVM9VHPWW6"
};
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)
const storage = getStorage(app)

export { auth, db, storage }
