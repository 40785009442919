<template>
  <p class="atFoot foot">
    For any technical support please:&nbsp;
    <a
      href="mailto:dev@stanleyparkcreative.com"
      target="_blank"
      class="atFoot terms"
    >
      email us</a
    >
  </p>
</template>

<style scoped>
.foot {
  text-align: center;
}
</style>
