import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { auth } from "./firebase/config"
import PrimeVue from "primevue/config"
import vueVimeoPlayer from "vue-vimeo-player";

import Foot from "./components/foot.vue";

import "primevue/resources/themes/saga-blue/theme.css" //theme
import "primevue/resources/primevue.min.css" //core css
import "primeicons/primeicons.css" //icons
import "primeflex/primeflex.css" //grid system
import Button from 'primevue/button';
import InputSwitch from 'primevue/inputswitch';
import Dropdown from 'primevue/dropdown';
import Avatar from 'primevue/avatar';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Dialog from 'primevue/dialog';
import InputMask from 'primevue/inputmask';
import InputText from 'primevue/inputtext';


let pv = () => {
    app.component("Button", Button)
    app.component("InputSwitch", InputSwitch)
    app.component("Dropdown", Dropdown)
    app.component("Avatar", Avatar)
    app.component("Accordion", Accordion)
    app.component("AccordionTab", AccordionTab)
    app.component("Dialog", Dialog)
    app.component("InputMask", InputMask)
    app.component("InputText", InputText)

    app.component("vueVimeoPlayer", vueVimeoPlayer)
    app.component("Foot", Foot)
}

let app
auth.onAuthStateChanged(() => {
    if (!app) {
        app = createApp(App).use(router).use(PrimeVue).use(vueVimeoPlayer)
        pv()
        app.mount('#app')
    }
})