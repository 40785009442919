<template>
  <Nav :changes="list" />
  <div class="headG">
    <h1 class="titleG">Your Case list</h1>
    <p class="subtitleG">Watch, listen, and learn at your own pace.</p>
  </div>
  <div class="cardG">
    <div v-for="(caseReport, i) in cases" :key="i">
      <Case
        :caseNo="caseReport.case"
        :dr="caseReport.dr"
        :report="caseReport.report"
      >
        <template v-slot:butt>
          <Button
            label="Info"
            class="p-button-raised p-button-info but"
            @click="goToCase(i)"
            >Open Case</Button
          >
          <!-- <Button
            v-else
            label="Info"
            class="p-button-raised p-button-info but"
            @click=""
            disabled
            >Coming Soon</Button
          > -->
        </template>
      </Case>
    </div>
  </div>
  <div>
    <Foot class="footi" />
  </div>
</template>

<script>
import Nav from "../components/nav";
import Case from "../components/case";
import { useRouter } from "vue-router";
import { cases } from "../modules/data";
import { onMounted } from "vue";
import getDocument from "../modules/getDocument";
import { auth } from "../firebase/config";
export default {
  components: { Case, Nav },
  setup() {
    const router = useRouter();
    const list = [{ name: "My Profile" }, { name: "Logout" }];
    const goToCase = (event) => {
      router.push({ name: "caseReport", params: { number: event } });
    };
    onMounted(async () => {
      const { data, docError } = await getDocument(
        auth.currentUser.email,
        true
      );
      if (docError.value) {
        console.log(docError.value);
      }
    });
    return { cases, list, goToCase };
  },
};
</script>

<style scoped>
.but {
  position: absolute;
  right: 6%;
  top: 20%;
  bottom: 20%;
}

.footi {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 95vh;
  text-align: center;
}

@media (max-height: 1000px) {
  .footi {
    position: initial;
  }
}

@media (max-width: 450px) {
  .cardG {
    width: 100%;
  }
}

@media (max-width: 300px) {
  .but {
    font-size: 0.5rem;
  }
}
</style>
