<template>
  <img src="../assets/photos/logo.png" alt="image is not available" />
  <p class="danger">{{ signupError }}</p>
  <form autocomplete="false">
    <input
      type="text"
      autocomplete="false"
      placeholder="Name"
      v-model="userName"
      ref="userNameRef"
      required
    />
    <input
      placeholder="Email"
      type="text"
      autocomplete="false"
      v-model="email"
      ref="emailRef"
      required
    />
    <input
      type="password"
      placeholder="Password"
      v-model="password"
      ref="passwordRef"
      required
    />
    <input
      type="password"
      placeholder="Confirm Password"
      v-model="confPass"
      ref="confPassRef"
      required
    />
    <div class="p-grid drop">
      <Dropdown
        class="p-lg-5 p-md-5 p-col-12"
        v-model="selectedPract"
        :options="pract"
        ref="practRef"
        optionLabel="name"
        optionValue="value"
        placeholder="Practitioner"
      />
      <Dropdown
        class="p-lg-6 p-md-6 p-col-12"
        v-model="selectedProv"
        :options="prov"
        ref="provRef"
        optionLabel="name"
        optionValue="value"
        placeholder="Province"
      />
    </div>
    <div v-if="selectedProv && selectedPract">
      <input
        type="number"
        placeholder="Medical License Number"
        v-model="licNo"
        ref="licNoRef"
        required
      />
    </div>
    <div v-else ref="licNoRef"></div>
    <span class="switch">
      <InputSwitch v-model="showPass" @click="ShowPassword" class="toggle" />
      <p class="atFoot">Show Password</p>
    </span>
    <div class="switch">
      <InputSwitch v-model="agreeTerms" @click="acceptTerms" class="toggle" />
      <p class="atFoot">
        I agree to the
        <span class="atFoot terms" @click="showTerms"
          >Terms and Conditions</span
        >
      </p>
      <Dialog
        header="Onco-Minds Terms & Conditions"
        v-model:visible="displayDialog"
        :style="{ width: '50vw' }"
      >
        <p>
          I agree to allow Care Education Inc. (iCare) to contact me by email to
          grant access to their academic programs, or update me when new
          programs have launched. I may withdraw my consent at any time by
          sending an email to info@stanleyparkcreative.com with the subject line
          <i>Unsubscribe from Onco-minds.com</i>.
        </p>
        <template #footer>
          <Button
            label="Close"
            icon="pi pi-times"
            @click="closeDialog"
            class="p-button-text"
            autofocus
          />
        </template>
      </Dialog>
    </div>
    <Button
      v-if="!isLoading"
      label="Info"
      class="p-button-outlined p-button-info"
      @click="register"
      >Register Now</Button
    >
    <div v-else>
      <br />
      <div>
        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
      </div>
      <br />
      <Button
        label="Info"
        class="p-button-outlined p-button-info"
        style="color: #2c3e50"
        disabled
        @click="register"
        >Register Now</Button
      >
    </div>
  </form>
</template>

<script>
import { ref } from "vue";
import useSignup from "../modules/useSignup";
import addDocument from "../modules/addDocument";
import { auth } from "../firebase/config";
import { serverTimestamp } from "firebase/firestore";
import { useRouter } from "vue-router";
import { prov, pract, licMask } from "../modules/data";
import addPeople, { addWebsite } from "../modules/addPeople";
export default {
  setup() {
    const router = useRouter();
    const logo = ref(null);
    const signupError = ref(null);
    const userName = ref(null);
    const userNameRef = ref(null);
    const email = ref(null);
    const emailRef = ref(null);
    const password = ref(null);
    const passwordRef = ref(null);
    const confPass = ref(null);
    const confPassRef = ref(null);
    const licNo = ref(null);
    const licNoRef = ref(null);
    const licNoCorrect = ref(false);
    const showPass = ref(false);
    const agreeTerms = ref(false);
    const selectedProv = ref(null);
    const provRef = ref(null);
    const selectedPract = ref(null);
    const practRef = ref(null);
    const danger = "border-bottom: 2px solid var(--danger)";
    const ordinary = "border-bottom: 1px solid var(--ordinary)";
    const isLoading = ref(false);
    const register = async () => {
      signupError.value = null;
      licNoCorrect.value = false;
      passwordRef.value.style = ordinary;
      confPassRef.value.style = ordinary;
      licNoRef.value.style = ordinary;
      if (selectedPract.value && selectedProv.value) {
        var licRules = licMask[selectedPract.value][selectedProv.value];
      }
      if (
        userName.value &&
        email.value &&
        password.value &&
        confPass.value &&
        selectedPract.value &&
        selectedProv.value &&
        licNo.value
      ) {
        if (password.value !== confPass.value) {
          signupError.value = "Passwords do not match";
          passwordRef.value.style = danger;
          confPassRef.value.style = danger;
        } else if (licRules) {
          let licNoStr = licNo.value.toString();
          if (licRules.includes(licNoStr.length)) {
            licNoCorrect.value = true;
          } else {
            signupError.value = "License number invalid!!";
            licNoRef.value.style = danger;
          }
        } else {
          if (licNo.value) {
            licNoCorrect.value = true;
          } else {
            signupError.value = "License number invalid!!";
            licNoRef.value.style = danger;
          }
        }
        if (licNoCorrect.value) {
          if (agreeTerms.value) {
            isLoading.value = true;
            const { error, redEmail, redPassword } = await useSignup(
              email.value,
              password.value,
              userName.value
            );
            if (error.value) {
              signupError.value = error.value;
              isLoading.value = false;
              if (redEmail.value) {
                emailRef.value.style = danger;
              }
              if (redPassword.value) {
                passwordRef.value.style = danger;
              }
            } else {
              const document = ref({
                name: userName.value,
                email: email.value.toLowerCase(),
                license: licNo,
                userId: auth.currentUser.uid,
                course: { 0: [], 1: [], 2: [], 3: [], 4: [] },
                visited: 1,
                firstRegistered: serverTimestamp(),
                timeVisited: serverTimestamp(),
                clinicName: "",
                Province: selectedProv.value,
                Practitioner: selectedPract.value,
                postal: "",
                address: "",
                phone: "",
              });
              const { docError } = await addDocument(document.value);
              if (docError.value) {
                signupError.value = docError.value;
                isLoading.value = false;
              } else {
                const { addPeopleError } = await addPeople(
                  document.value.email
                );
                const { addWebsiteError } = await addWebsite(
                  document.value.email,
                  "Sleep"
                );
                if (addPeopleError.value || addWebsiteError.value) {
                  console.log("error");
                  isLoading.value = false;
                }
                router.push({ name: "main" });
              }
            }
          } else {
            signupError.value =
              "You Must Agree Terms and Conditions to be able to signup!!";
          }
        }
      } else {
        signupError.value =
          "Please Fill up all the Fields to continue in signing up process";
        if (!userName.value) {
          userNameRef.value.style = danger;
        } else {
          userNameRef.value.style = ordinary;
        }
        if (!email.value) {
          emailRef.value.style = danger;
        } else {
          emailRef.value.style = ordinary;
        }
        if (!password.value) {
          passwordRef.value.style = danger;
        } else {
          passwordRef.value.style = ordinary;
        }
        if (!confPass.value) {
          confPassRef.value.style = danger;
        } else {
          confPassRef.value.style = ordinary;
        }
        if (!selectedPract.value || !selectedProv.value || !licNo.value) {
          licNoRef.value.style = danger;
        } else {
          licNoRef.value.style = ordinary;
        }
      }
    };
    const ShowPassword = () => {
      if (!showPass.value) {
        passwordRef.value.type = "text";
        confPassRef.value.type = "text";
        showPass.value = false;
      } else {
        passwordRef.value.type = "password";
        confPassRef.value.type = "password";
        showPass.value = true;
      }
    };
    const acceptTerms = () => {
      agreeTerms.value = !agreeTerms.value;
    };
    const displayDialog = ref(false);
    const showTerms = () => {
      displayDialog.value = true;
    };
    const closeDialog = () => {
      displayDialog.value = false;
    };
    document.addEventListener("keydown", (e) => {
      if (e.key === "Enter") {
        register();
      }
    });

    return {
      logo,
      signupError,
      userName,
      userNameRef,
      email,
      emailRef,
      password,
      confPass,
      licNo,
      register,
      passwordRef,
      confPassRef,
      licNoRef,
      showPass,
      ShowPassword,
      prov,
      pract,
      provRef,
      practRef,
      selectedProv,
      selectedPract,
      displayDialog,
      showTerms,
      closeDialog,
      agreeTerms,
      acceptTerms,
      isLoading,
    };
  },
};
</script>

<style scoped>
form {
  max-width: 550px;
}
img {
  display: block;
  margin: 20px auto 10px auto;
  width: 200px;
  height: auto;
}
.card {
  margin: 200px auto;
}
.switch {
  display: flex;
  align-items: center;
  justify-content: left;
}
.toggle {
  margin: 0 10px 0 0;
}
.p-dropdown {
  min-width: 100px;
  border: transparent 1px solid;
  border-bottom-color: var(--ordinary);
}
.p-dropdown:not(p-disable).p-focus {
  box-shadow: none;
}
.drop {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: 0 20px;
}
@media (max-width: 650px) {
  form {
    margin: 50px 20px;
  }
}
@media (max-width: 500px) {
  .drop {
    display: block;
  }
  .p-col-5 {
    display: inline-block;
    width: 90%;
  }
}
@media (max-width: 300px) {
  .atFoot {
    font-size: 0.6rem;
  }
}
</style>
