<template>
  <Nav :changes="listP" />
  <div class="headG">
    <h1 class="titleG">Welcome {{ profileName }}</h1>
    <p class="subtitleG">Edit Your Profile here...</p>
  </div>
  <div class="cardG">
    <p class="danger">{{ error }}</p>
    <form id="profile">
      <label>Name</label>
      <input type="text" v-model="profileInfo.name" />
      <div class="flex">
        <div class="line">
          <label>Email Address</label>
          <input
            type="text"
            v-model="profileInfo.email"
            disabled
            class="disabled"
          />
        </div>
        <div class="line">
          <Button
            label="Info"
            class="p-button-outlined p-button-info password"
            @click="updatPass"
            >Reset Password</Button
          >
        </div>
      </div>
      <div class="flex">
        <div class="line">
          <label class="push-down">Clinic Name</label>
          <input type="text" v-model="profileInfo.clinicName" />
        </div>
        <div class="line">
          <label>Medical License Number</label>
          <input
            type="text"
            v-model="profileInfo.license"
            disabled
            class="disabled"
          />
        </div>
      </div>
      <div class="p-grid">
        <div class="p-lg-8 p-md-8 p-sm-12">
          <label>Clinic Address</label>
          <input
            style="margin-top: 10px"
            type="text"
            placeholder="1234 Main St, Toronto, ON"
            v-model="profileInfo.address"
          />
        </div>
        <div class="p-lg-3 p-md-3 p-sm-6">
          <label>Postal Code</label>
          <InputMask v-model="profileInfo.postal" mask="a9a 9a9" />
        </div>
      </div>
      <div class="p-grid">
        <div class="p-lg-7 p-md-6 p-sm-12">
          <label>Practitioner</label>
          <Dropdown
            v-model="profileInfo.Practitioner"
            :options="pract"
            optionLabel="name"
            optionValue="value"
            placeholder="Practitioner"
            disabled
          />
        </div>
        <div class="p-lg-5 p-md-5 p-sm-12">
          <label>Province</label>
          <Dropdown
            v-model="profileInfo.Province"
            :options="prov"
            optionLabel="name"
            optionValue="value"
            placeholder="Select a Province"
            disabled
          />
        </div>
      </div>
      <label>Phone Number</label>
      <InputMask v-model="profileInfo.phone" mask="(999) 999-9999" />
      <Button
        label="Info"
        class="p-button-outlined p-button-info push-down"
        @click="update"
        >Update Profile</Button
      >
    </form>
  </div>
  <Foot />
</template>

<script>
import { onMounted, ref } from "vue";
import { prov, pract } from "../modules/data";
import Nav from "../components/nav";
import { auth } from "../firebase/config";
import getDocument from "../modules/getDocument";
import updateProfile from "../modules/updateProfile";
import updatePassword from "../modules/updatePassword";
export default {
  components: { Nav },
  props: ["id"],
  setup() {
    const error = ref(null);
    const profileInfo = ref({
      name: null,
      email: null,
      clinicName: null,
      Province: null,
      Practitioner: null,
      postal: null,
      license: null,
      address: null,
      phone: null,
    });
    const listP = [{ name: "Case List" }, { name: "Logout" }];
    const profileName = ref(auth.currentUser.displayName);
    const update = async () => {
      const { profError } = await updateProfile(profileInfo.value);
      if (!profError.value) {
        error.value = "Profile Updated";
      } else {
        error.value = profError.value;
      }
    };
    const updatPass = async () => {
      const { updatePasswordError } = await updatePassword(
        profileInfo.value.email
      );
      if (!updatePasswordError.value) {
        error.value = "Email Sent";
      } else {
        error.value = updatePasswordError.value;
      }
    };
    onMounted(async () => {
      const { data, docError } = await getDocument(
        auth.currentUser.email,
        false
      );
      if (!docError.value) {
        profileInfo.value.name = data.value.name;
        profileInfo.value.email = data.value.email;
        profileInfo.value.clinicName = data.value.clinicName;
        profileInfo.value.postal = data.value.postal;
        profileInfo.value.Province = data.value.Province;
        profileInfo.value.Practitioner = data.value.Practitioner;
        profileInfo.value.license = data.value.license;
        profileInfo.value.address = data.value.address;
        profileInfo.value.phone = data.value.phone;
      } else {
        error.value = docError.value;
      }
    });
    return {
      profileInfo,
      listP,
      prov,
      pract,
      profileName,
      error,
      update,
      updatPass,
    };
  },
};
</script>

<style scoped>
.cardG {
  width: 60%;
}
form {
  margin: 10px 30px;
  padding: 20px;
}
label {
  display: block;
  text-align: left;
  margin-left: 10px;
  color: gray;
  font-size: 0.8rem;
  font-style: italic;
}
input {
  width: 90%;
  margin: 0 10px 20px;
  padding: 5px;
  font-size: 1rem;
}
input:focus {
  width: 80%;
}
.disabled {
  color: dimgrey;
}
.flex {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.line {
  display: inline-block;
  width: 45%;
}
.p-dropdown {
  min-width: 200px;
  border: transparent 1px solid;
  border-bottom-color: var(--ordinary);
}
.p-dropdown:not(p-disable).p-focus {
  box-shadow: none;
}
.password {
  display: inline-block;
  width: 90%;
}
.p-inputmask {
  border: 0;
  border-bottom: 1px solid var(--ordinary);
  padding: 10px;
  outline: none;
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin: 20px auto;
  margin: 0 0 10px;
}
.p-inputmask:focus {
  border-bottom: 2px solid var(--safe);
  width: 97%;
  box-shadow: none;
}
@media (max-width: 900px) {
  .cardG {
    width: 90%;
  }
  .flex {
    display: block;
  }
  .line {
    display: block;
    width: 90%;
  }
  .push-down {
    margin-top: 15px;
  }
}
@media (max-width: 760px) {
  .p-grid {
    display: block;
  }
  .p-dropdown {
    width: 80%;
  }
}
@media (max-width: 350px) {
  .p-dropdown {
    min-width: 100px;
  }
}
</style>